import Sortable from 'sortablejs';
import { fabric } from 'fabric';

import { loadImage, uploadFile } from '../utils';
import ApplicationController from './application_controller';

const CONTAINER_WIDTH = 554;
const CONTAINER_HEIGHT = 725;

export default class extends ApplicationController {
  static targets = [
    'list',
    'logoParameters',
    'backgroundCanvas',
    'backgroundImage',
    'containerPercentHeightInput',
    'containerPercentHeightRange',
    'form'
  ];

  index = 0;

  async initialize() {
    this.sanitizeIndex();
    await this.initializePreview();

    document.addEventListener('stimulus-reflex:after', async ({ target }) => {
      try {
        this.parentAfterReflex(target);

        this.sanitizeIndex();

        await this.initializePreview();
      } catch (error) {
        console.error(error);
      }
    });

    this.initializeTabs();
  }

  connect() {
    super.connect();

    this.tabsLink = $('.tabs .master-blank-image-container');
    this.tabsContent = $('.forms > div');

    this.sortable = Sortable.create(this.listTarget, {
      animation: 150,
      onEnd: evt => this.handlePositionChange(evt)
    });
  }

  sanitizeIndex() {
    const isTabExists = document.getElementById(`thumbnail-${this.index}`);

    if (!isTabExists) {
      this.lastOpenedTab = null;
      this.index = 0;
    }
  }

  initializeTabs() {
    $(this.element).on('click', '.tabs .tab-link', event => {
      event.preventDefault();

      const link = $(event.currentTarget);
      this.openTab(link);
    });
  }

  openTab(linkObject) {
    const hrefId = linkObject.attr('href');
    this.tabsLink.removeClass('outline-strong');
    linkObject.parent().addClass('outline-strong');

    if (hrefId.charAt(0) === '#') {
      this.tabsContent.addClass('d-none');
      $(hrefId).removeClass('d-none');
      this.lastOpenedTab = linkObject;
    }
  }

  afterReflex() {
    this.tabsLink = $('.tabs .master-blank-image-container');
    this.tabsContent = $('.forms > div');

    this.sanitizeIndex();

    if (this.lastOpenedTab) {
      this.openTab(this.lastOpenedTab);
    }
  }

  parentAfterReflex(target) {
    if (target.length === 0) return;

    const invalidElement = $(target)
      .find('.is-invalid')
      .closest("[id^='tab-']");

    if (invalidElement.length > 0) {
      const tabWithInvalidField = $(target).find(
        `[href='#${invalidElement.attr('id')}']`
      );

      if (tabWithInvalidField.length > 0) {
        this.openTab(tabWithInvalidField);
      }
    }
  }

  async handleFileSelect(event) {
    event.preventDefault();
    this.forceShowLoadingMessage();

    const fileInput = event.target;

    const {
      dataset: { directUploadUrl },
      files
    } = fileInput;

    const upload = uploadFile(directUploadUrl);

    let secondary_image_id =
      parseInt(this.element.getAttribute('data-items'), 10) - 1;

    const promises = Array.from(files).map(upload);
    const blobs = await Promise.all(promises);
    const images = blobs.map(blob => ({
      ...blob,
      secondary_image_id: ++secondary_image_id
    }));

    this.stimulate('MasterBlankReflex#add_secondary_images', { images });

    fileInput.value = null;
  }

  handlePositionChange({ newIndex, oldIndex }) {
    if (newIndex === oldIndex) return;

    const positions = this.formTargets
      .map(({ value }, index) => ({
        index,
        position: value ? parseInt(value) : index
      }))
      .sort((a, b) => a.position - b.position);

    if (oldIndex < newIndex) {
      for (let i = oldIndex + 1; i <= newIndex; i++) {
        positions[i]['position'] = i - 1;
      }
    } else {
      for (let i = newIndex; i < oldIndex; i++) {
        positions[i]['position'] = i + 1;
      }
    }

    positions[oldIndex]['position'] = newIndex;

    positions.forEach(({ index, position }) => {
      this.formTargets[index].value = position;
    });

    this.lastOpenedTab = null;
    this.index = 0;
  }

  async createBackgroundCanvas() {
    const backgroundImage = await loadImage(
      this.backgroundImageTargets[this.index].src
    );

    this.image = new fabric.Image(backgroundImage);

    this.ratio = 1;

    if (
      this.image.width > CONTAINER_WIDTH ||
      this.image.height > CONTAINER_HEIGHT
    ) {
      if (
        this.image.width / CONTAINER_WIDTH >
        this.image.height / CONTAINER_HEIGHT
      ) {
        this.ratio = CONTAINER_WIDTH / this.image.width;
      } else {
        this.ratio = CONTAINER_HEIGHT / this.image.height;
      }
    }

    this.maxContainerPercentHeight = Math.round(
      (this.ratio * this.image.height * 100) / CONTAINER_HEIGHT
    );

    if (this.containerPercentHeight === 0) {
      this.containerPercentHeightInput.value = this.maxContainerPercentHeight;
      this.containerPercentHeightRange.value = this.maxContainerPercentHeight;
    }

    this.containerPercentHeightInput.max = this.maxContainerPercentHeight;
    this.containerPercentHeightRange.max = this.maxContainerPercentHeight;

    const ratio = this.ratio * (this.containerPercentHeight / 100);

    const backgroundCanvas = new fabric.Canvas(this.backgroundCanvasTarget, {
      width: this.image.width * ratio,
      height: this.image.height * ratio
    });

    backgroundCanvas.setBackgroundImage(
      this.image,
      backgroundCanvas.renderAll.bind(backgroundCanvas),
      { scaleX: ratio, scaleY: ratio }
    );

    return backgroundCanvas;
  }

  async initializePreview() {
    const isImagesTab =
      document.getElementById('secondary-images-controller')?.offsetHeight > 0;

    if (!this.hasBackgroundImageTarget || !isImagesTab) return;

    if (this.backgroundCanvas) {
      this.backgroundCanvas.dispose();
    }

    this.logoParametersTarget.classList = 'logo-parameters';

    this.backgroundCanvas = await this.createBackgroundCanvas();
    this.backgroundCanvas.renderAll();
  }

  async drawImageToCanvas({ target }) {
    try {
      const {
        dataset: { index }
      } = target;

      this.index = parseInt(index, 10);
      await this.initializePreview();
    } catch (error) {
      console.error(error);
    }
  }

  handleChangeContainerPercentHeight({ target }) {
    let { value } = target;

    if (value === '' || parseFloat(value) <= 0) {
      value = 1;
    } else if (parseFloat(value) > this.maxContainerPercentHeight) {
      value = this.maxContainerPercentHeight;
    }

    this.containerPercentHeightInput.value = value;
    this.containerPercentHeightRange.value = value;

    const ratio = this.ratio * (this.containerPercentHeight / 100);

    this.backgroundCanvas.setHeight(this.image.height * ratio);
    this.backgroundCanvas.setWidth(this.image.width * ratio);

    this.image.set('scaleX', ratio);
    this.image.set('scaleY', ratio);

    this.backgroundCanvas.renderAll();
  }

  get containerPercentHeightInput() {
    return this.containerPercentHeightInputTargets[this.index];
  }

  get containerPercentHeightRange() {
    return this.containerPercentHeightRangeTargets[this.index];
  }

  get containerPercentHeight() {
    const { value } = this.containerPercentHeightInput;
    const containerPercentHeight = +value;

    return parseInt(
      (containerPercentHeight / this.maxContainerPercentHeight) * 100,
      10
    );
  }
}
