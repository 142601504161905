import ApplicationController from './application_controller';

export default class extends ApplicationController {
  accessLevelUpdate(event) {
    const { value } = event.target;
    const { index } = event.target.dataset;

    this.companyGroupUpdate(value, index);
    this.billToAccountUpdate(value, index);
  }

  companyGroupUpdate(value, index) {
    // DashboardToolAccess::LEVEL_1, DashboardToolAccess::LEVEL_4, DashboardToolAccess::LEVEL_6
    if (['1', '4', '6'].includes(value)) {
      this.unsetDisableMulti(index, 'company_group');
    } else {
      this.setDisableMulti(index, 'company_group');
    }
  }

  billToAccountUpdate(value, index) {
    // DashboardToolAccess::LEVEL_1, DashboardToolAccess::LEVEL_4
    if (['1', '4'].includes(value)) {
      this.unsetDisable(index, 'bill_to_account');
    } else {
      this.setDisable(index, 'bill_to_account');
    }
  }

  unsetDisableMulti(index, field) {
    const accessor = `select#user_dashboard_tool_accesses_attributes_${index}_${field}_ids`;

    $(accessor).prop('disabled', false);
    $(accessor).siblings().removeClass('disabled');
    $(accessor)
      .closest('.dropdown.bootstrap-select.select')
      .removeClass('disabled');
    $(accessor)
      .closest(`.user_dashboard_tool_accesses_${field}s`)
      .removeClass('disabled');
  }

  setDisableMulti(index, field) {
    const accessor = `select#user_dashboard_tool_accesses_attributes_${index}_${field}_ids`;

    $(accessor).prop('disabled', true);
    $(accessor).siblings().addClass('disabled');
    $(accessor)
      .closest('.dropdown.bootstrap-select.select')
      .addClass('disabled');
    $(accessor)
      .closest(`.user_dashboard_tool_accesses_${field}s`)
      .addClass('disabled');
  }

  unsetDisable(index, field) {
    const accessor = `select#user_dashboard_tool_accesses_attributes_${index}_${field}_id`;

    $(accessor).prop('disabled', false);
    $(accessor).siblings().removeClass('disabled');
    $(accessor)
      .closest('.dropdown.bootstrap-select.select')
      .removeClass('disabled');
    $(accessor)
      .closest(`.user_dashboard_tool_accesses_${field}`)
      .removeClass('disabled');
  }

  setDisable(index, field) {
    const accessor = `select#user_dashboard_tool_accesses_attributes_${index}_${field}_id`;

    $(accessor).prop('disabled', true);
    $(accessor).siblings().addClass('disabled');
    $(accessor)
      .closest('.dropdown.bootstrap-select.select')
      .addClass('disabled');
    $(accessor)
      .closest(`.user_dashboard_tool_accesses_${field}`)
      .addClass('disabled');
  }
}
