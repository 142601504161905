import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = [
    'checkAllCheckbox',
    'itemCheckbox',
    'placeOrderButton',
    'stageOrderButton'
  ];
  checkedItems = [];
  pickup_order = null;

  connect() {
    super.connect();
  }

  toggleCheckAll({ currentTarget }) {
    if (currentTarget.checked) {
      this.checkAll();
    } else {
      this.uncheckAll();
    }
    this.refreshOrderButtons();
  }

  checkAll() {
    this.itemCheckboxTargets.forEach(item => {
      if (item.checked) return;
      item.checked = true;
    });
  }

  uncheckAll() {
    this.itemCheckboxTargets.forEach(item => {
      if (!item.checked) return;
      item.checked = false;
    });
  }

  toggleItemCheck({ currentTarget }) {
    this.refreshOrderButtons();
  }

  refreshOrderButtons() {
    const checkedItemsCount = $(
      "input[name='striven_item_id'][type='checkbox']:checked"
    ).length;
    this.placeOrderButtonTarget.disabled = checkedItemsCount <= 0;
    this.stageOrderButtonTarget.disabled = checkedItemsCount <= 0;
  }

  populateCheckedItems() {
    if (this.checkedItems.length > 0) return;

    $("input[name='striven_item_id'][type='checkbox']:checked")
      .get()
      .forEach(item => {
        const inputObject = $(item);
        const orderItemInput = inputObject
          .closest('tr')
          .find('input[name="order_items_input"');

        if (orderItemInput.val() !== '0') {
          this.checkedItems.push({
            striven_item_identifier: inputObject.val(),
            vendor_name: inputObject.data('vendor-name'),
            part_number: inputObject.data('part-number'),
            order_qty: orderItemInput.val()
          });
        }
      });
  }

  createPurchaseOrder() {
    this.forceShowLoadingMessage();

    this.populateCheckedItems();

    $.ajax({
      url: '/manage/materials_orderings/purchase_orders',
      data: JSON.stringify({
        purchase_order_items: this.checkedItems,
        options: {
          pickup_order: this.pickup_order
        }
      }),
      method: 'POST',
      contentType: 'application/json; charset=uft-8',
      success: () => {
        this.forceHideLoadingMessage();
      },
      error: data => {
        console.log(data);
        this.forceHideLoadingMessage();
      }
    });
  }

  stagePurchaseOrder() {
    this.forceShowLoadingMessage();

    $("input[name='striven_item_id'][type='checkbox']:checked")
      .get()
      .forEach(item => {
        const inputObject = $(item);
        const orderItemInput = inputObject
          .closest('tr')
          .find('input[name="order_items_input"');

        if (orderItemInput.val() !== '0') {
          this.checkedItems.push({
            striven_id: inputObject.val(),
            part_number: inputObject.data('part-number'),
            order_qty: orderItemInput.val()
          });
        }
      });

    $.ajax({
      url: '/manage/materials_orderings/staged_items',
      data: JSON.stringify({ staged_items: this.checkedItems }),
      method: 'POST',
      contentType: 'application/json; charset=uft-8',
      success: () => {
        this.forceHideLoadingMessage();
      },
      error: data => {
        console.log(data);
        this.forceHideLoadingMessage();
      }
    });
  }

  isSanmarItemSelected() {
    return this.checkedItems.find(item => this.isSanmarItem(item));
  }

  isSanmarItem(item) {
    return item.vendor_name
      .replace(/\s+/g, '')
      .toLowerCase()
      .includes('sanmar');
  }

  async confirmOrder(event) {
    event.preventDefault();

    this.populateCheckedItems();

    if (this.isSanmarItemSelected()) {
      const pickupOrderModal = this.getPickupOrderModal();
      this.pickup_order = await pickupOrderModal.getUserDecision();
    }

    this.createPurchaseOrder();
  }

  getPickupOrderModal() {
    const modalElement = document.getElementById('pickupOrderModal');

    return this.application.getControllerForElementAndIdentifier(
      modalElement,
      'pickup-order-modal'
    );
  }

  disconnect() {
    super.disconnect();
  }
}
