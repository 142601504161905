import { Modal } from 'bootstrap';

import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = ['modal'];

  connect() {
    this.resolveUserDecision = null;
    this.modalInstance = null;
  }

  getUserDecision() {
    this.show();

    return new Promise(decision => {
      this.resolveUserDecision = decision;
    });
  }

  show() {
    if (!this.modalInstance) {
      this.modalInstance = new Modal(this.modalTarget);
    }

    this.modalInstance.show();
  }

  hide() {
    this.modalInstance.hide();
  }

  approve = () => {
    this.resolveUserDecision(true);
    this.hide();
  };

  reject = () => {
    this.resolveUserDecision(false);
    this.hide();
  };
}
