import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = ['checkAllCheckbox', 'itemCheckbox', 'placeOrderButton'];
  checkedItems = [];
  pickup_order = null;

  connect() {
    super.connect();
  }

  toggleCheckAll({ currentTarget }) {
    if (currentTarget.checked) {
      this.checkAll();
    } else {
      this.uncheckAll();
    }
    this.refreshOrderButton();
  }

  checkAll() {
    this.itemCheckboxTargets.forEach(item => {
      if (item.checked) return;
      item.checked = true;
    });
  }

  uncheckAll() {
    this.itemCheckboxTargets.forEach(item => {
      if (!item.checked) return;
      item.checked = false;
    });
  }

  toggleItemCheck({ currentTarget }) {
    this.refreshOrderButton();
  }

  refreshOrderButton() {
    const checkedItemsCount = $(
      "input[name='striven_item_id'][type='checkbox']:checked"
    ).length;
    this.placeOrderButtonTarget.disabled = checkedItemsCount <= 0;
  }

  createPurchaseOrder() {
    this.forceShowLoadingMessage();

    this.populateCheckoutItems();

    $.ajax({
      url: '/manage/materials_orderings/staged_items/purchase_orders',
      data: JSON.stringify({
        purchase_order_items: this.checkedItems,
        options: {
          pickup_order: this.pickup_order
        }
      }),
      contentType: 'application/json; charset=uft-8',
      method: 'POST',
      success: () => {
        this.forceHideLoadingMessage();
      },
      error: data => {
        console.log(data);
        this.forceHideLoadingMessage();
      }
    });
  }

  populateCheckoutItems() {
    if (this.checkedItems.length > 0) return;

    $("input[name='striven_item_id'][type='checkbox']:checked")
      .get()
      .forEach(item => {
        const inputObject = $(item);

        this.checkedItems.push({
          striven_item_identifier: inputObject.val(),
          vendor_name: inputObject.data('vendor-name'),
          part_number: inputObject.data('part-number'),
          order_qty: inputObject.data('staged-qty')
        });
      });
  }

  isSanmarItemSelected() {
    return this.checkedItems.find(item => this.isSanmarItem(item));
  }

  isSanmarItem(item) {
    return item.vendor_name
      .replace(/\s+/g, '')
      .toLowerCase()
      .includes('sanmar');
  }

  async confirmPurchaseOrder(event) {
    event.preventDefault();

    this.populateCheckoutItems();

    if (this.isSanmarItemSelected()) {
      const pickupOrderModal = this.getPickupOrderModal();
      this.pickup_order = await pickupOrderModal.getUserDecision();
    }

    this.createPurchaseOrder();
  }

  getPickupOrderModal() {
    const modalElement = document.getElementById('pickupOrderModal');

    return this.application.getControllerForElementAndIdentifier(
      modalElement,
      'pickup-order-modal'
    );
  }

  disconnect() {
    super.disconnect();
  }
}
