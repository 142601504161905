import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = ['handlingCharge'];

  connect() {
    super.connect();
  }

  more_options(event) {
    const { index } = event.currentTarget.dataset;

    const moreOptions = document.getElementById(`more-options-${index}`);
    const moreOptionsIcon = document.getElementById(
      `dripicons-chevron-${index}`
    );

    moreOptions.classList.toggle('hidden');
    moreOptionsIcon.classList.toggle('dripicons-chevron-down');
    moreOptionsIcon.classList.toggle('dripicons-chevron-up');
  }

  toggle_disable_handling_charge(event) {
    const isChecked = event.target.checked;
    const handlingChargeInput = this.handlingChargeTarget;

    if (isChecked) {
      handlingChargeInput.checked = false;
      handlingChargeInput.disabled = true;
    } else {
      handlingChargeInput.disabled = false;
    }
  }
}
